//Text 
.right{
	text-align:right !important;
}
.center{
	text-align:center !important;
}
.placeholder {
	color: #999;
}

/**
 * Sizes for loops
 * @type Array
 */
$sizes: ('xs', 'sm', 'md', 'lg');

/**
 * Margin size for margin helper
 * @type Integer
 */
$margin-size: 5;

// Generate the margins helper
@include margins-helper($margin-size);

.list-styled {
	list-style: initial;
}