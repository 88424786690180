[class*="sprite-"] {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
}

.efc-quality [class*="sprite-"] {
	margin:0 30px;
}
.efc-guaranty [class*="sprite-"] {
}

.sprite-some-question {
    background-image: url(../img/sprite/some-question.png);
    height: 66px;
    width: 66px;
}

.sprite-government {
    height: 30px;
    width: 36px;
    background-image: url(../img/sprite/government.png);
}

.sprite-student-group {
    background-image: url(../img/sprite/student-group.png);
    height: 35px;
    width: 64px;
}


.sprite-check {
    background-image: url(../img/sprite/check.png);
    height: 58px;
    width: 55px;
}


.sprite-internet {
    background-image: url(../img/sprite/internet.png);
    height: 41px;
    width: 58px;
}

.sprite-help {
    background-image: url(../img/sprite/help.png);
    height: 31px;
    width: 52px;
}


.sprite-more-time {
    background-image: url(../img/sprite/more-time.png);
    height: 48px;
    width: 48px;
}

.sprite-OPQF-logo {
    background-image: url(../img/sprite/OPQF-logo.png);
    height: 100px;
    width: 128px;
}

.sprite-chaned {
    background-image: url(../img/sprite/chaned.png);
    height: 120px;
    width: 120px;
}