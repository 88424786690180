.lp-specific-offre-49 {
	background: #f1f6fb;
	border-radius:3px 3px 0;

	h2 {
		font-size:20px;
	}

	.grey {
		color:grey;
	}
	.btn-group-center {
		margin:0 auto;
	}
	.formation-price-accroche {
		font-weight: 300;
		font-size: 24px;
		color:#888A8B;
	}

	.btn-efc-blue-light{
		border: 2px solid #6bb7f8;
		border-left:2px solid #6bb7f8;
		//border-left:1px solid #6bb7f8;
		color: #6bb7f8;
		font-weight:600;
		background-color: white;
		@include transition(all .3s ease-in);

		/* &:first-child {
		}
		&:last-child {
			border-right:2px solid #6bb7f8;
		} */

		&:hover, &:focus{
			color: white;
			background-color: #6bb7f8;
			@include transition(all .3s ease-in);
		}
		&.active {
			color: white;
			background-color: #6bb7f8;
			@include transition(all .3s ease-in);
			&:hover, &:focus{
				background-color: #6bb7f8;
				@include transition(all .3s ease-in);
			}
		}
	}
	.has-error {
		.btn-efc-blue-light{
			border-color: #E0B1B1;
			color: #E0B1B1;
			&:first-child {
				border-left:2px solid #E0B1B1;
			}
			&:last-child {
				border-right:2px solid #E0B1B1;
			}

			&:hover, &:focus{
				color: white;
				background-color: #E0B1B1;
			}
			&.active {
				color: white;
				background-color: #E0B1B1;
				&:hover, &:focus{
					background-color: #E0B1B1;
				}
			}
		}
	}

	.form-without-borders {
		input {
			border:0;
			border-bottom: 1px dashed grey;
			border-radius: 0;
			background:transparent;
			&.resize {
			    max-width: 368px;
				min-width:100px;
				width:100px;
			    @include transition(width 0.25s);
			}
			&.cp {
				max-width: 64px;
				min-width:64px;
				width:64px;
			}
		}
		select {
			border:0px;
			background:transparent;
		}
	}
	.form-group {
		position: relative;
		min-height: 34px;
		white-space: nowrap;
		&.has-error {
			input, select {
				background:#f2dede;
			}
		}
	}
	.label-print {
		padding: 6px 12px;
		height: 34px;
		line-height: 24px;
		vertical-align: middle;
		display: inline-block;
	}
}

@media print {
	html, body {
		font-size:10px;
		color:#333;
	}
    figure.logo-efc {
    	margin:0;
    	a[href]:after {
    		content: "";
    	}
		img {
			height:60px;
		}
    }
	header .baseline h1 {
		font-size:24px !important;
		border:0 !important;
	}
	h2 {
		font-size: 18px;
		&.price-reduce {
			padding:4px;
			font-size:12px !important;
			border:1px solid #333 !important;
			margin-top:0;
			margin-bottom:0;
		}
	}
	#core {
		margin-top:10px !important;
	}
	.form-without-border {
		input {
			border: 0px white solid !important;
			&.resize {
				max-width:80px;
				min-width:80px;
				width:80px;
			}
			&.cp {
				max-width:80px;
				min-width:80px;
				width:80px;
			}
		}
	}
	.form-control {
		font-size: 10px;
		padding: 6px;
	}
	.form-group {
		display: inline-block;
	}
	.box-signature {
		min-height:50px !important;
		border:1px #333 solid !important;
	}
}

@media (min-width: 480px) {
	.form-inline .form-control, .navbar-form .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle;
	}
}

