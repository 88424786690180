/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	.display-table-sm {
		display: table;
	}
	.display-table-row-sm {
		display: table-row;
	}
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}


/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
	.form-control{
		font-size:12px;
		padding: 6px 6px;
	}

	.doc-demand{
		.doc-demand-text{
			width:215px;
		}
		.doc-demand-icon{
			width: 66px;
			//padding-top: 20px;
			padding-left: 5px;
		}
		span{
			display:inline-block;
			&.sprite-some-question{
				@include scale(.85);
			}
			&.primary{
				font-size:34px;
				line-height: 64px;
			}
			&.secondary{
				font-size:29px;
				line-height: 8px;
			}
		}
	}

	.efc-guaranty{
		.item{
			.item-icon{
				width:58px;
			}
			.item-desc{
				width:443px;
			}
		}
	}
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	html body{
		background-position: -895px top;
	}

	header{
		.baseline{
			h1,h2{
				background:rgba(255,255,255,.4);
			}
		}
	}

	.form-control{
		font-size:10px;
		padding: 6px 6px;
	}

	.efc-quality{
		font-size:16px;
	}

	.doc-demand{
		.doc-demand-text{
			padding:0px;
			width:100%;

		}
		span{
			display:inline-block;
			&.primary{
				font-size:34px;
				line-height: 64px;
			}
			&.secondary{
				font-size:29px;
				line-height: 8px;
			}
		}
	}

	.efc-guaranty{
		.item{
			margin:8px 0;
			.item-icon{
				width:58px;
			}
			.item-desc{
				width:294px;
			}
		}
	}
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	html body{
		background-position: -1095px top;
	}

	header{
		background:rgba(255,255,255,.2);
	}


	.clearfix-after-form{
		display:none;
	}
	.form-control{
		font-size:14px;
		padding: 6px 12px;
	}

	.efc-quality{
		font-size:18px;
	}
	.efc-guaranty{
		li{
			margin:5px 0;
		}
		.item{
			margin:15px 0;
			.item-desc{
				width:426px;
				width:calc( 100% - 168px );
			}
		}
	}
	#core{
		.container-left{
			padding-right:0px;
		}
		.container-right{
			hr{
				display:none;
			}
		}
		.container-left, .container-right{
			display: block;
			float:left;
		}
	}

	.partenaires{
		.col-lg-6{
			margin:40px 0;
		}
	}

	.doc-demand{
		.doc-demand-icon{
			padding-left:10px;
		}
		.doc-demand-text{
			width:auto;
			padding-top:20px;
			display:inline-block;
		}
		span{
			&.sprite-some-question{
				@include scale(1);
			}
			&.primary{
				font-size:34px;
				line-height: 64px;
			}
			&.secondary{
				font-size:34px;
				line-height: 64px;
			}
		}
	}
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 620px) {
	.doc-demand{
		.doc-demand-icon{
			padding-left:10px;
		}
		.doc-demand-text{
			width:auto;
			padding-top:20px;
			display:inline-block;
		}
		span{
			&.sprite-some-question{
				display:none;
			}
			&.primary{
				font-size:34px;
				line-height: 64px;
			}
			&.secondary{
				font-size:29px;
				line-height: 16px;
				margin-bottom: 10px;
				display:block;
			}
		}
	}
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 580px) {
	.efc-guaranty{
		.item{
			margin:25px 0;
		}
	}
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 580px) {
	#core{
		.container-right{
			hr{
				display:block;
			}
		}
	}
	#contact, .partenaires{
		width:100%;
	}

	.partenaires{
		.col-md-6{
			margin:0px 0;
			width:50%;
			float: left;
		}
	}
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
	header{
		padding-bottom:75px;
		figure{
			img{
				width:100%;
			}
		}
		.baseline{
			h1{
				font-size:36px;
			}
			h2{
				font-size:18px;
			}
		}

	}

    #core{
    	margin-top:0;
    	padding:0;
		.container-left, .container-right{
			height:auto;
		}
	}
	.testimony{
		.col-sm-12{
			padding:0;
			.efc-testimony{
				padding:0 15px;
			}
		}
	}

	.efc-guaranty{
		.item{
			margin:5% 0;
			.item-icon{
				width:90%;
			}
			.item-desc{
				width:90%;
				text-align:center;
			}
		}
	}

	.efc-quality{
		.row{
			padding:5px 15px;
			margin:0;
		}
		.sprite{
			display: block;
			margin: 0 auto;
		}
	}


}

/* Custom, iPhone Retina */
@media only screen and (max-width : 380px) {
	.partenaires{
		.col-md-6{
			margin:10px 0;
			width:100%;
		}
	}
}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
    #core{
		.container-left, .container-right{
			height:auto;
		}
	}

	.partenaires{
		.col-md-6{
			@include scale(.75);
		}
	}
}