/**
 * Margin mixin
 * @param  Integer $add-margin
 */
@mixin margins-helper($add-margin) {

	.no-margin-top {
		margin-top: 0px;
	}
	.no-margin-bottom {
		margin-bottom: 0px;
	}
	.no-margin-left {
		margin-left: 0px;
	}
	.no-margin-right {
		margin-right: 0px;
	}	

	.no-margin {
		margin: 0px !important;
	}	

	$margin: $add-margin;

	@each $size in $sizes {
	  .margin-top-#{$size} {
	    margin-top: #{$margin}px;
	  }
	  .margin-bottom-#{$size} {
	    margin-bottom: #{$margin}px;
	  } 
	  .margin-left-#{$size} {
	    margin-left: #{$margin}px;
	  }
	  .margin-right-#{$size} {
	    margin-right: #{$margin}px;
	  } 

	  $margin: $margin+$add-margin;    
	}
}

/**
 * Padding mixin
 * @param  Integer $add-padding
 */
@mixin paddings-helper($add-padding) {

	.no-padding-top {
		padding-top: 0px;
	}
	.no-padding-bottom {
		padding-bottom: 0px;
	}
	.no-padding-left {
		padding-left: 0px;
	}
	.no-padding-right {
		padding-right: 0px;
	}

	.no-padding {
		padding: 0px !important;
	}	

	$padding: $add-padding;

	@each $size in $sizes {
	  .padding-top-#{$size} {
	    padding-top: #{$padding}px;
	  }
	  .padding-bottom-#{$size} {
	    padding-bottom: #{$padding}px;
	  } 
	  .padding-left-#{$size} {
	    padding-left: #{$padding}px;
	  }
	  .padding-right-#{$size} {
	    padding-right: #{$padding}px;
	  } 

	  $padding: $padding+$add-padding;    
	}
}